import React, { Component } from "react";

const windowGlobal = typeof window !== "undefined" && window;

class LinkTo extends Component {
    redirect = site => {
        if (windowGlobal) {
            windowGlobal.location.href = site;
        }
    };

    render() {
        const sited = this.props.pageContext.item;
        const url = sited.link;

        return (
          <div>
              {url && (
                <div
                  onClick={this.redirect(url)}
                >
                </div>)}
          </div>
        );
    }

}

export default LinkTo;
